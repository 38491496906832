import React from 'react';

const GameIcon = () => {
  return (
    <svg t="1680024751042" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
         p-id="12415" width="16" height="16">
      <path d="M653.8 414m-52.1 0a52.1 52.1 0 1 0 104.2 0 52.1 52.1 0 1 0-104.2 0Z" fill="#282828" p-id="12416"></path>
      <path
        d="M462.9 386.2h-45.1v-45.1c0-17.4-10.4-27.8-27.8-27.8s-27.8 13.9-27.8 27.8v45.1h-45.1c-17.4 0-27.8 6.9-27.8 24.3 0 17.3 13.9 27.8 27.8 27.8h45.1v45.1c0 17.3 13.9 27.8 27.8 27.8s24.3-10.4 27.8-24.3v-48.6h45.1c13.9 0 27.8-6.9 27.8-24.3s-13.9-27.8-27.8-27.8z"
        fill="#282828" p-id="12417"></path>
      <path
        d="M664.2 119c-20.8 0-45.1 10.4-69.4 20.8-27.8 10.4-59 24.3-83.3 24.3-27.8 0-55.5-13.9-83.3-24.3-27.8-10.4-48.6-20.8-69.4-20.8C206.1 119 102 386.2 102 563.2c0 45.1 3.5 79.8 17.4 107.6 17.4 34.7 45.1 52.1 76.3 52.1 14.4 0 29.3-1.4 45-4.4 47.1 74.4 140.1 119.4 218 133.1 34.9 6 68.8 20.2 98.5 43 4.2 4.4 10.2 7.2 16.8 7.2 12.9 0 23.3-10.4 23.3-23.3 0-4.4-3.5-14.5-12.6-20.7-43.3-32.7-91.8-47.6-148.6-54.9-62.1-10.6-115.7-46.5-149.4-96.9 33.9-11.7 72.1-29.9 117.2-56 34.7-20.8 69.4-27.8 107.6-27.8 34.7 0 69.4 6.9 104.1 27.8 90.2 52.1 152.7 72.9 208.2 72.9 34.7 0 62.5-20.8 79.8-52.1C914.1 643 921 608.3 921 563.2c0-177-100.6-444.2-256.8-444.2z m190.9 527.5c-6.9 20.8-20.8 24.3-27.8 24.3-48.6 0-100.6-17.4-183.9-65.9-41.6-24.3-79.8-34.7-125-34.7H515c-48.6 0-90.2 10.4-131.9 34.7-83.3 45.1-138.8 65.9-183.9 65.9-6.9 0-17.4-3.5-27.8-24.3-10.4-17.4-13.9-48.6-13.9-83.3C157.5 407 247.7 171 362.3 171c6.9 0 27.8 10.4 48.6 17.4 31.2 13.9 69.4 31.2 104.1 31.2 38.2 0 72.9-17.4 104.1-31.2 17.4-6.9 34.7-17.4 45.1-17.4C775.3 171 869 407 869 563.2c0 34.7-3.5 62.5-13.9 83.3z"
        fill="#282828" p-id="12418"></path>
    </svg>
  );
};

export default GameIcon;