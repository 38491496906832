import React from 'react';

const AlgorithmIcon = () => {
  return (
    <svg className="icon" width="16px" height="16.00px" viewBox="0 0 1024 1024" version="1.1"
         xmlns="http://www.w3.org/2000/svg">
      <path fill="#333333"
            d="M512.099979 47.98969c22.895081 0 45.990119 9.717912 68.655249 28.883794 25.1346 21.255433 49.149441 53.988401 69.445081 94.529691 21.685341 43.360684 38.781668 94.229755 50.829079 151.187518 12.657281 59.85714 19.075902 123.553455 19.075902 189.339322s-6.418621 129.482182-19.075902 189.339322C688.961981 758.217102 671.875652 809.046181 650.190311 852.446857 629.894671 893.028138 605.879831 925.721115 580.755228 947.016539c-22.655133 19.165882-45.750171 28.883794-68.655249 28.883795s-45.990119-9.717912-68.65525-28.883795c-25.1346-21.255433-49.139443-53.988401-69.44508-94.52969-21.685341-43.360684-38.781668-94.229755-50.82908-151.187519C310.513288 641.402199 304.044678 577.705884 304.044678 511.920017s6.418621-129.482182 19.075902-189.339321c12.047412-56.987757 29.153737-107.826834 50.829079-151.187519 20.29564-40.591279 44.31048-73.274257 69.44508-94.529691 22.705122-19.155884 45.810158-28.873797 68.70524-28.873796m0-47.98969c-141.379625 0-255.945012 229.200758-255.945012 511.890024s114.615376 511.890024 255.945012 511.890023 255.945012-229.200758 255.945011-511.890023-114.615376-511.890024-255.945011-511.890024z"/>
      <path fill="#333333"
            d="M512.099979 47.98969c22.895081 0 45.990119 9.717912 68.655249 28.883794 25.1346 21.255433 49.149441 53.988401 69.445081 94.529691 21.685341 43.360684 38.781668 94.229755 50.829079 151.187518 12.657281 59.85714 19.075902 123.553455 19.075902 189.339322s-6.418621 129.482182-19.075902 189.339322C688.961981 758.217102 671.875652 809.046181 650.190311 852.446857 629.894671 893.028138 605.879831 925.721115 580.755228 947.016539c-22.655133 19.165882-45.750171 28.883794-68.655249 28.883795s-45.990119-9.717912-68.65525-28.883795c-25.1346-21.255433-49.139443-53.988401-69.44508-94.52969-21.685341-43.360684-38.781668-94.229755-50.82908-151.187519C310.513288 641.402199 304.044678 577.705884 304.044678 511.920017s6.418621-129.482182 19.075902-189.339321c12.047412-56.987757 29.153737-107.826834 50.829079-151.187519 20.29564-40.591279 44.31048-73.274257 69.44508-94.529691 22.705122-19.155884 45.810158-28.873797 68.70524-28.873796m0-47.98969c-141.379625 0-255.945012 229.200758-255.945012 511.890024s114.615376 511.890024 255.945012 511.890023 255.945012-229.200758 255.945011-511.890023-114.615376-511.890024-255.945011-511.890024z"/>
      <path fill="#333333"
            d="M260.624007 221.282459c104.277597 0 233.839761 40.281346 355.473628 110.506258 56.987757 32.892933 108.926598 70.294898 154.426823 111.186113 43.310695 38.921638 78.813068 79.132999 105.527328 119.614301 24.99463 37.871863 41.301127 74.98389 47.14987 107.406925 5.268868 29.203726 2.129542 54.068384-9.317998 73.894124-10.297788 17.836168-27.544082 31.923142-51.268985 41.881002-26.564293 11.147605-59.877136 16.796391-98.978735 16.796391-104.287594 0-233.859757-40.281346-355.493625-110.506258-56.987757-32.892933-108.926598-70.294898-154.426822-111.186112-43.310695-38.91164-78.80307-79.162992-105.51733-119.614302-24.99463-37.871863-41.301127-74.98389-47.149871-107.406924-5.268868-29.203726-2.129542-54.068384 9.317998-73.894125 10.297788-17.836168 27.544082-31.923142 51.268986-41.881002 26.564293-11.147605 59.887134-16.796391 99.028724-16.796391m0-47.98969c-89.790709 0-159.825662 27.194158-191.848783 82.672239C-1.889594 378.398703 139.250083 592.262756 384.097479 733.622386c133.921228 77.31339 271.091758 116.974869 379.488469 116.974868 89.790709 0 159.825662-27.194158 191.848783-82.672238 70.694812-122.473687-70.51485-336.33774-315.342251-477.69737-133.921228-77.31339-271.091758-116.974869-379.488469-116.974868z"/>
      <path fill="#333333"
            d="M763.615942 221.282459c39.141591 0 72.444436 5.648786 98.978735 16.796391 23.714905 9.997852 40.991193 23.994845 51.258987 41.881002 11.447541 19.825741 14.586866 44.690399 9.317998 73.894125-5.838746 32.393041-22.145242 69.535061-47.14987 107.406924-26.704263 40.461307-62.206635 80.702662-105.51733 119.614302-45.480229 40.891215-97.439066 78.293179-154.406827 111.19611-121.633868 70.214915-251.20603 110.496261-355.483626 110.49626-39.151589 0-72.464431-5.648786-98.978735-16.796391-23.714905-9.997852-40.991193-23.994845-51.268986-41.881002-11.447541-19.825741-14.586866-44.690399-9.317998-73.894124 5.838746-32.393041 22.145242-69.535061 47.149871-107.406925 26.664271-40.481303 62.166644-80.692664 105.467341-119.614301 45.510222-40.891215 97.459062-78.303177 154.426822-111.186113 121.633868-70.224913 251.20603-110.496261 355.503622-110.506258m0-47.98969c-108.386714 0-245.587237 39.621488-379.488469 116.974869C139.250083 431.587276-1.889594 645.451329 68.755228 767.885025c31.993126 55.478081 102.048076 82.672238 191.848783 82.672238 108.386714 0 245.587237-39.621488 379.488469-116.974869C884.91988 592.262756 1026.129542 378.398703 955.444729 255.965008c-31.993126-55.478081-102.048076-82.672238-191.848783-82.672239z"/>
      <path fill="#333333"
            d="M480.006874 536.104821H352.034368a23.994845 23.994845 0 0 1 0-47.989689h127.972506a23.994845 23.994845 0 0 1 0 47.989689z"/>
      <path fill="#333333"
            d="M416.020621 600.091074a23.994845 23.994845 0 0 1-23.994845-23.994845V448.123723a23.994845 23.994845 0 0 1 47.989689 0v127.972506a23.994845 23.994845 0 0 1-23.994844 23.994845zM671.965632 536.104821h-95.979379a23.994845 23.994845 0 0 1 0-47.989689h95.979379a23.994845 23.994845 0 0 1 0 47.989689z"/>
    </svg>
  );
};

export default AlgorithmIcon;