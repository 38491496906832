import React from 'react';

const TextIcon = () => {
  return (
    <svg className="icon" width="16px" height="16.00px" viewBox="0 0 1024 1024" version="1.1"
         xmlns="http://www.w3.org/2000/svg">
      <path fill="#333333"
            d="M584.106667 203.392h367.786666a29.44 29.44 0 1 1 0 58.88h-367.786666a29.44 29.44 0 1 1 0-58.88z"/>
      <path fill="#333333"
            d="M682.666667 518.272m29.44 0l239.786666 0q29.44 0 29.44 29.44l0 0q0 29.44-29.44 29.44l-239.786666 0q-29.44 0-29.44-29.44l0 0q0-29.44 29.44-29.44Z"/>
      <path fill="#333333"
            d="M162.133333 426.666667m29.44 0l282.453334 0q29.44 0 29.44 29.44l0 0q0 29.44-29.44 29.44l-282.453334 0q-29.44 0-29.44-29.44l0 0q0-29.44 29.44-29.44Z"/>
      <path fill="#333333"
            d="M42.653932 706.809843m12.441882-26.681701l263.623636-565.342714q12.441882-26.681701 39.123583-14.239819l0 0q26.681701 12.441882 14.23982 39.123583l-263.623637 565.342713q-12.441882 26.681701-39.123583 14.23982l0 0q-26.681701-12.441882-14.239819-39.123583Z"/>
      <path fill="#333333"
            d="M649.088578 706.82928m-12.441882-26.681701l-263.623637-565.342714q-12.441882-26.681701-39.123582-14.239819l0 0q-26.681701 12.441882-14.23982 39.123583l263.623637 565.342713q12.441882 26.681701 39.123583 14.23982l0 0q26.681701-12.441882 14.239819-39.123583Z"/>
      <path fill="#333333"
            d="M42.666667 833.152m29.44 0l879.786666 0q29.44 0 29.44 29.44l0 0q0 29.44-29.44 29.44l-879.786666 0q-29.44 0-29.44-29.44l0 0q0-29.44 29.44-29.44Z"/>
    </svg>
  );
};

export default TextIcon;