import React from 'react';

const UtilIcon = () => {
  return (
    <svg t="1680087562433" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
         p-id="1640" width="16" height="16">
      <path
        d="M916.422378 713.465454l-200.653973-200.639646c12.921287-36.074613 19.461237-73.782424 19.461237-112.426559 0-184.603415-150.179394-334.799182-334.799182-334.799182-45.959751 0-90.675162 9.263991-132.8671 27.525913l-19.960611 8.640798c-0.031722 0.031722 0.124843 0.047072 0.062422 0.077771-1.898233 0.826831-3.674693 2.025123-5.231141 3.581572-6.602372 6.615675-6.602372 17.374716 0.032746 24.007787 0.093121 0.094144-0.094144 0.140193-0.032746 0.233314l187.452299 187.453323L317.133336 429.856142 133.447826 246.156306c-0.280386 0.031722-0.560772 0.047072-0.934279-0.326435-7.78429-7.78429-20.395516-7.78429-28.181853 0-2.116197 2.133593-3.674693 4.609994-4.63967 7.256264-0.093121 0.248663-0.217964 0.124843-0.341784 0.140193l-6.197142 14.323217c-18.24555 42.145889-27.52489 86.860277-27.52489 132.851751 0 184.588065 150.180418 334.799182 334.799182 334.799182 38.611389 0 76.3192-6.538927 112.438838-19.461237l200.654996 200.626343c27.092032 27.088962 63.119573 42.035372 101.450577 42.035372 0 0 0 0 0.032746 0 38.299281 0 74.358545-14.94641 101.449553-42.035372C972.347085 860.439854 972.347085 769.422907 916.422378 713.465454zM871.365182 871.275643c-15.07023 15.071254-35.093263 23.352871-56.360635 23.352871l-0.032746 0c-21.298072 0-41.320081-8.28264-56.359612-23.352871l-230.764757-230.735082-19.896142 8.594749c-34.158984 14.789844-70.342068 22.292726-107.521852 22.292726-149.432381 0-271.027763-121.562637-271.027763-271.02674 0-20.769023 2.335185-41.212634 6.974855-61.141522l168.582533 168.614256c7.068999 5.637394 16.782222 5.714142 23.976064 0.35918l177.239703-177.23868c0.062422-0.062422-0.063445-0.141216 0-0.202615 7.223518-7.224542 7.441483-18.497283 1.24434-26.328645L339.272565 136.330992c19.960611-4.623297 40.386826-6.958482 61.155848-6.958482 149.432381 0 271.028786 121.581056 271.028786 271.027763 0 37.226856-7.505951 73.392544-22.294772 107.491153l-8.594749 19.895119 230.765781 230.765781C902.410246 789.631158 902.410246 840.197834 871.365182 871.275643z"
        fill="#272636" p-id="1641"></path>
    </svg>
  );
};

export default UtilIcon;