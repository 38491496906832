import React from 'react';

const AnimateIcon = () => {
  return (
    <svg className="icon" width="16px" height="16.00px" viewBox="0 0 1024 1024" version="1.1"
         xmlns="http://www.w3.org/2000/svg">
      <path fill="#333333"
            d="M328.546 136.956l43.259 164.107 137.814 49.708-137.218 51.295-41.274 164.503-43.259-164.007-137.913-49.708 137.317-51.295zM616.18 184.58l53.776 108.743 103.782 14.983-88.799 55.661-5.358 121.244-53.776-108.743-103.782-14.983 88.799-55.661zM344.421 575.301l24.209 68.857 60.921 16.569-57.149 26.69-12.103 72.132-24.111-68.957-60.921-16.569 57.149-26.789z m96.142-40.183l81.26-81.26c17.462-17.462 46.135-17.462 63.598 0L708.153 576.59 563.295 721.448 440.563 598.617c-17.462-17.462-17.462-46.037 0-63.5z m295.77 69.65L591.475 749.626l124.518 124.518c17.462 17.462 46.135 17.462 63.598 0l81.26-81.26c17.462-17.462 17.462-46.135 0-63.598L736.333 604.768zM457.332 573.02l102.393 102.493L662.218 573.02 559.725 470.527 457.332 573.02z"/>
    </svg>
  );
};

export default AnimateIcon;